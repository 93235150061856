.navLink:after {
    display: block;
    content: " ";
    width: 0%;
    height: 2px;
    background: var(--chakra-colors-gold-500);
    transition: width 0.2s;
    margin-top: -2px;
}
.linkActive {
    color: var(--chakra-colors-gold-500);
    cursor: default;
    pointer-events: none;
}
.navLink.linkActive:after {
    width: 100%;
}

.navLink {
    transition: color 0.2s;
}
.navLink:hover {
    color: var(--chakra-colors-gold-500);
}

.navIcon {
    width: 26px;
    height: 26px;
    border: none;
    outline: none;
    cursor: pointer;
    pointer-events: all;
}

.navIcon:after,
.navIcon:before,
.navIcon div {
    background-color: #fff;
    content: "";
    display: block;
    height: 3px;
    margin: 5px 0px;
    transition: all 0.2s ease-in-out;
}

.navIconInvisible {
    width: 26px;
    height: 26px;
    border: none;
    outline: none;
    cursor: pointer;
    pointer-events: all;
}

.navIconInvisible:after,
.navIconInvisible:before,
.navIconInvisible div {
    content: "";
    display: block;
    height: 3px;
    margin: 5px 0px;
    transition: all 0.2s ease-in-out;
}

.navIcon.open:before {
    transform: translateY(8px) rotate(135deg);
}

.navIcon.open:after {
    transform: translateY(-8px) rotate(-135deg);
}

.navIcon.open div {
    transform: scale(0);
}
