html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

#__next {
    min-height: 100vh;
    position: relative;
}

.swiper-pagination-bullet {
    width: 24px !important;
    height: 24px !important;
    border: 1.5px solid rgb(132, 112, 112) !important;
    background: transparent !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    position: relative;
    margin-top: 30px;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 10px !important;
    border: 2px solid #fffc !important;
}
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:hover {
    margin: 0 10px !important;
    border: 2px solid #fffc !important;
    background: linear-gradient(0deg, #e93766 0%, #e93766 100%) !important;
}

.swiper-pagination-bullet-active {
    background: linear-gradient(0deg, #e93766 0%, #e93766 100%) !important;
}

/* TODO: CHECK BELOW BREAKS EVERYWHERE */

/* .customized-paginated.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet {
    opacity: 1 !important;
    width: 100px !important;
    height: 100px !important;
    margin-top: 20px !important;
    border: none !important;
    background-image: url('../public/icons/pagination-middle.svg') !important;
} */

/* .customized-paginated.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet:first-child {
    background-image: url('../public/icons/pagination-left.svg') !important;
}

.customized-paginated.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet:last-child {
    background-image: url('../public/icons/pagination-right.svg') !important;
} */

/* .customized-paginated.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet:last-child.swiper-pagination-bullet-active {
    background-image: url('../public/icons/pagination-right-gradient.svg') !important;
} */

/* .customized-paginated.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet:first-child.swiper-pagination-bullet-active {
    background-image: url('../public/icons/pagination-left-gradient.svg') !important;
} */

/* .customized-paginated.swiper-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-image: url('../public/icons/pagination-middle-gradient.svg') !important;
} */

/* .customized-paginated.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, 
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
     margin: -24px !important; 
} */

@keyframes skeleton {
    0% {
        left: -130%;
    }
    20% {
        left: -130%;
    }
    80% {
        left: 130%;
    }
    100% {
        left: 130%;
    }
}

@keyframes skeleton-lg {
    0% {
        left: -140%;
    }
    100% {
        left: 140%;
    }
}

.banner-slider.swiper-horizontal > .swiper-button-prev {
    opacity: 1;
    z-index: 1;
    border-radius: 40px;
    background-color: #3f4964;
    padding: 32px !important;
    border: 2px solid #62677a;
    color: white;
}

.banner-slider.swiper-horizontal > .swiper-button-next {
    opacity: 1;
    z-index: 1;
    border-radius: 40px;
    background-color: #3f4964;
    padding: 32px !important;
    border: 2px solid #62677a;
    color: white;
}

.betaGateForm input:-webkit-autofill,
.betaGateForm input:-webkit-autofill:hover,
.betaGateForm input:-webkit-autofill:focus,
.betaGateForm input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #31384f inset !important;
    -webkit-text-fill-color: white !important;
}

.smallScroll::-webkit-scrollbar {
    width: 7px;
}

.tooltipHover {
    height: 20px;
    position: absolute;
    transform-origin: center center;
    transform: translateX(-50%);
    bottom: -20px;
    left: 50%;
}

.tooltipArrow {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ddd;
    transform: translateX(-50%) rotate(-90deg);
    bottom: -13px;
    left: 50%;
    z-index: 9;
}

.tooltipArrow:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid var(--chakra-colors-blue-dark);
    top: -9px;
    left: 1px;
    z-index: 10;
    position: absolute;
}

.no-scrollbars {
    scrollbar-width: none;
}
.no-scrollbars::-webkit-scrollbar {
    width: 0;
    display: none;
}

.capitalize-first:first-letter {
    text-transform: uppercase;
}

.pulse-dot {
    background: rgba(233, 56, 102, 1);
    box-shadow: 0 0 0 0 rgba(233, 56, 102, 1);
    animation: pulse-dot 2s infinite;
}

@keyframes pulse-dot {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(233, 56, 102, 0.7);
    }

    60% {
        transform: scale(1);
        box-shadow: 0 0 0 7px rgba(233, 56, 102, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(233, 56, 102, 0);
    }
}
